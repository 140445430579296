.content {
  color: var(--app-text-color);
  text-align: center;
  max-width: 960px;
  margin: 100px auto 184px;

  >h1,
  >h2 {
    color: #030303;
    font-size: 3.375rem;
    line-height: 1.2;
    font-weight: bold;
    // margin-top: 2rem;
    // margin-bottom: 50px;
    font-family: Inter, Inter;

    .tracker {
      margin-top: 1rem;
      color: var(--app-text-color);
    }
  }

  >h2 {
    color: var(--app-primary-color);
  }

  >p {
    margin-top: 3rem;
    // font-size: 1.5rem;
    // padding: 0 2rem;
    // color: var(--app-text-60-color);

    font-size: 24px;
    font-family: Inter, Inter;
    font-weight: 400;
    color: #909093;
    line-height: 34px;
    margin-bottom: 4rem;
  }

  .list {
    margin: 0 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    justify-content: space-evenly;
    grid-gap: 10px;

    .listItem {
      display: flex;
      align-items: center;
      justify-content: center;

      >:first-child {
        margin-right: 5px;
      }
    }
  }

  .btnContainer {
    margin-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;

    .seeAnalyticsSample,
    .goToDashboard {
      display: inline-block;
      height: 3.375rem;
      line-height: 3.375rem;
      margin-top: 1rem;
      border-radius: 1.6875rem;
      width: 18rem;
      font-size: 1rem;
      font-weight: bold;
      background: linear-gradient(180deg, #725CFF 0%, rgba(141, 131, 255, 0.8) 100%);
      border: 1px solid var(--app-primary-color);
      color: var(--foreground-rgb);
      margin-left: 10px;
      margin-right: 10px;
      cursor: pointer;
    }

    .seeAnalyticsSample {
      background: var(--foreground-rgb);
      color: var(--app-primary-color);
      border: 1px solid var(--app-primary-color);
    }

  }
}

@media screen and (max-width: 786px) {
  .content {

    .list {
      grid-template-columns: 5fr 3fr;

      .listItem {
        justify-content: flex-start;
      }
    }

    .btnContainer {
      flex-direction: column-reverse;
    }
  }
}

@media screen and (min-width: 960px) {
  .content {
    margin-top: 150px;
    margin-bottom: 200px;

    >h1,
    >h2 {
      font-size: 3rem;
      // margin-top: 1.75rem;
      // margin-bottom: 20px;
    }
  }
}

@media (max-width: 960px) and (min-width: 768px) {
  .content {
    margin-top: 70px;
    margin-bottom: 154px;
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .content {
    margin-top: 30px;
    // margin-bottom: 119px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 576px) {
  .content {
    margin-top: 10px;
    // margin-bottom: 50px;
    margin-bottom: 10px;

    >h1,
    >h2 {
      font-size: 2rem;
      margin-top: 0;
      // margin-bottom: 20px;

      .tracker {
        font-size: 1.5rem;
      }
    }

    >p {
      font-size: 18px;
      margin-bottom: 2rem;
    }

    .btnContainer {
      margin-top: 30px;
    }

    .list {
      margin: 0;
      gap: 5px;

      .listItem {
        font-size: 14px;
      }
    }

  }
}
